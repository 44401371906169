/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import { isNil } from "lodash";
import socket, {
  emitAnnotationCreated,
  emitAnnotationUpdated,
  emitAnnotationDeleted,
  emitPageScrolling,
  emitZoomUpdated,
  emitRotationUpdated,
  emitPageNumberUpdated,
  emitDisplayModeUpdated,
  TYPE_MESSAGE,
  TYPE_ANNOTATOR_CHANGED
} from "./useSocket";
import {
  importFile,
  viewFile,
  listAnnotations,
  saveAnnotation,
  getNextExhibitNumber,
  publishExhibitAndUpdateExhibitNumber
} from "../services/EditorService";
import { getRoom } from "../services/RoomService";
import ExpressUtils from "@pdftron/pdfjs-express-utils";

const utils =
  process.env.REACT_APP_PDFJS_SERVER_LICENSE === "dev" &&
  process.env.REACT_APP_PDFJS_CLIENT_LICENSE === "dev"
    ? new ExpressUtils()
    : new ExpressUtils({
        serverKey: `${process.env.REACT_APP_PDFJS_SERVER_LICENSE}`,
        clientKey: `${process.env.REACT_APP_PDFJS_CLIENT_LICENSE}`
      });

// Exhibit Modes
export const ADVANCED_MODE = 1;
export const SIMPLE_MODE = 2;
export const NO_EXHIBITS = 3;

let _instance;
let _eventId;
let _pdfId;
let _fileId;
let _isPreviewMode;
let _username;
let _filePermissions;
let _isMeetingRoom;
let _useDialog;
let _exhibitMode;
let _exhibitAnnotations = null;
let _exhibitNumber = "";
let _pdfStatus = "in-progress"; // in-progres | published
let _isExhibitsNumbersAutoIncremented = false;
let _presenter = null;
let _annotator = null;
let _exhibitSettings = {
  pageNumber: 0,
  rotation: 0,
  zoom: 1,
  layoutMode: "Continuous"
};
let _onExhibitNumberClick = null;
let _exhibitStampAnnotation = null;
let _onCloseClick = null;
let _onPublishExhibitConfirmClick = null;
let _onDownloadExhibitClick = null;
let _onLaunchExhibitEditor = null;
let _handleParticipantPublishState = null;
let _onAnnotationRightsClick = null;
let _onAnnotatorRightsChanged = null;
let _existingExhibitNumber = null;

export const initWebViewer = async ({
  instance,
  eventId,
  fileId,
  isPreview = true,
  username,
  filePermissions,
  exhibitSettings,
  isMeetingRoom = false,
  exhibitMode = ADVANCED_MODE,
  useDialog = true,
  onPublishExhibitConfirmClick = null,
  onPublishExhibitClick = null,
  onDownloadExhibitClick = null,
  onExhibitNumberClick = null,
  onCloseClick = null,
  onLaunchExhibitEditor = null,
  handleParticipantPublishState = null,
  onAnnotationRightsClick = null,
  onAnnotatorRightsChanged = null,
  existingExhibitNumber
}) => {
  // If preview mode, don't load all menu tools. Just the ones needed for preview.
  console.log("Initalizing WebViewer....", {
    eventId,
    isPreview,
    username,
    exhibitSettings,
    filePermissions,
    isMeetingRoom,
    exhibitMode,
    existingExhibitNumber
  });
  const isPreviewMode = isPreview;
  const isEditorMode = !isPreviewMode;
  _instance = instance;
  _eventId = eventId;
  _fileId = fileId;
  _isPreviewMode = isPreview;
  _username = username;
  _filePermissions = filePermissions;
  _exhibitSettings = exhibitSettings;
  _isMeetingRoom = isMeetingRoom;
  _exhibitMode = exhibitMode;
  _useDialog = useDialog;
  _onDownloadExhibitClick = onDownloadExhibitClick;
  _onCloseClick = onCloseClick;
  _existingExhibitNumber = existingExhibitNumber;

  const { documentViewer, annotationManager } = instance.Core;
  const { setTheme } = instance.UI;

  // Getting the scroll view port
  // const scrollView = documentViewer.getScrollViewElement();
  // Set dark mode for PDF editor and light mode for PDF viewer.
  isPreviewMode ? setTheme("light") : setTheme("dark");

  if (isEditorMode) {
    // Set the exhibit settings.   `presenter` and `annotator` should be under exhibitSettings.
    const roomInfo = await getRoom(_eventId);
    _presenter = roomInfo.appState.presenter;
    _annotator = roomInfo.appState.annotator;

    try {
      setExhibitSettings(roomInfo.exhibitSettings);
    } catch (error) {
      console.log("XXX error", error);
    }
  }

  // Set up header and toolbars
  console.log("Setting up header and toolbars...");
  updateHeaderAndToolbars(isPreviewMode);

  // Set up CocumentViewer event listeners
  addDocumentViewerEventListeners();

  // Load the exhibit into the webViewer
  loadExhibit(_fileId, isEditorMode);

  if (isEditorMode) {
    // Set appropriate event listeners for PDFJS editor mode.
    addSocketEventListeners();
    addAnnotationManagerEventListeners();

    // Set the current username for annotations in editor mode.
    annotationManager.setCurrentUser(_username);

    // Event Handlers
    _onExhibitNumberClick = onExhibitNumberClick;
    _onAnnotationRightsClick = onAnnotationRightsClick;
    _onPublishExhibitConfirmClick = onPublishExhibitConfirmClick;
    _onLaunchExhibitEditor = onLaunchExhibitEditor;
    _handleParticipantPublishState = handleParticipantPublishState;
    _onAnnotatorRightsChanged = onAnnotatorRightsChanged;
  }

  // Access documentViewer for event handling
  if (!isEditorMode) {
    documentViewer.addEventListener("documentLoaded", () => {
      console.log("Document loaded. Setting up pinch-to-zoom...");
      setupPinchToZoom(documentViewer, instance); // Call the function to enable pinch-to-zoom
    });
  }

  // Event Handlers for SocketIO Messages
  // Handling annotations, page number, zoom, rotation
  socket.on(TYPE_MESSAGE, (msg) => {
    const data = msg.data;
    console.log("inside socket data", data);

    // If this SocketIO message is updating the active presenter,
    // then update and return.
    if (data.type === TYPE_ANNOTATOR_CHANGED) {
      console.log("socketHandler: TYPE_ANNOTATOR_CHANGED", data);
      onAnnotatorChanged(data);
      return;
    }

    // Remove event listeners to prevent infinite loop of socketIO messages.
    // I'm not a big fan of this, but it doesn't seem to be a way to include
    // the original sender of the socketIO message.
    removeSocketEventListeners();
    switch (data.type) {
      case "pageScrollEvent":
        onPageScrolling(data);
        break;
      case "annotationCreated":
        onAnnotationCreated(data.annotation);
        break;
      case "annotationUpdated":
        onAnnotationUpdated(data.annotation);
        break;
      case "annotationDeleted":
        onAnnotationDeleted(data.annotationId);
        break;
      case "zoomUpdated":
        onZoomUpdated(data);
        break;
      case "rotationUpdated":
        onRotationUpdated(data);
        break;
      case "displayModeUpdated":
        onDisplayModeUpdated(data);
        break;
      case "exhibitPublishing":
        onExhibitPublish(data);
        break;
    }

    // Add event listeners back.
    addSocketEventListeners();
  });
};

/**
 * Sets up pinch-to-zoom functionality for the document viewer.
 * This function adds event listeners for `touchstart`, `touchmove`,
 * and `touchend` to enable users to zoom in and out using a pinch gesture.
 *
 * @param {object} documentViewer - The PDFTron document viewer instance.
 * @param {object} instance - The WebViewer instance used to access UI and other features.
 */
const setupPinchToZoom = (documentViewer, instance) => {
  // Get the scrollable viewer element
  const viewerElement = documentViewer.getScrollViewElement(); // Get the viewer element
  let startDistance = 0;
  let isPinching = false;

  /**
   * Calculates the distance between two touch points.
   * This is used to determine the pinch gesture's zoom level.
   *
   * @param {TouchList} touches - The list of touch points from the touch event.
   * @returns {number} - The distance between two touch points.
   */
  const calculateDistance = (touches) => {
    const dx = touches[0].clientX - touches[1].clientX;
    const dy = touches[0].clientY - touches[1].clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  /**
   * Handles the `touchstart` event.
   * Detects when two fingers are placed on the screen and initiates pinch-to-zoom.
   *
   * @param {TouchEvent} e - The touch event triggered on `touchstart`.
   */
  viewerElement.addEventListener("touchstart", (e) => {
    console.log("Touchstart Entering e.touches.length...", e.touches.length);
    if (e.touches.length > 1) {
      e.preventDefault(); // Prevent default touch behavior
      startDistance = calculateDistance(e.touches); // Store the initial distance
      isPinching = true; // Mark that a pinch gesture is in progress
      console.log("Pinch started");
    }
    console.log("Touchstart Exiting ...");
  });

  /**
   * Handles the `touchmove` event.
   * Calculates the new zoom level based on the change in distance between two touch points.
   *
   * @param {TouchEvent} e - The touch event triggered on `touchmove`.
   */
  viewerElement.addEventListener("touchmove", (e) => {
    if (isPinching && e.touches.length > 1) {
      console.log("touchmove Entering isPinching...", isPinching);
      e.preventDefault(); // Prevent default touch behavior
      const currentDistance = calculateDistance(e.touches); // Calculate the current distance
      const scaleFactor = currentDistance / startDistance; // Determine the zoom factor
      const currentZoom = documentViewer.getZoom(); // Get the current zoom level
      const newZoom = currentZoom * scaleFactor; // Apply the zoom factor

      // Log for debugging
      console.log(
        "Current distance:",
        currentDistance,
        "Scale factor:",
        scaleFactor
      );

      // Limit the zoom level within reasonable bounds
      const MIN_ZOOM = 0.5;
      const MAX_ZOOM = 5.0;
      const finalZoom = Math.max(MIN_ZOOM, Math.min(newZoom, MAX_ZOOM));
      console.log(
        "Pinching: Current Zoom Level documentViewer: ",
        documentViewer,
        "finalzoom: ",
        finalZoom
      );
      if (documentViewer.zoomTo) {
        documentViewer.zoomTo(finalZoom); // Update the document's zoom level
      } else if (documentViewer.setZoomLevel) {
        documentViewer.setZoomLevel(finalZoom); // Alternative for older versions
      } else {
        console.error("Zoom method not found on documentViewer.");
      }
      startDistance = currentDistance; // Update start distance for next move
      console.log("Pinching: Current Zoom Level startDistance", startDistance);
    }
    console.log("touchmove Exiting ...");
  });

  /**
   * Handles the `touchend` event.
   * Resets the pinch gesture state once the user lifts their fingers off the screen.
   *
   * @param {TouchEvent} e - The touch event triggered on `touchend`.
   */
  viewerElement.addEventListener("touchend", () => {
    console.log("touchend Entering isPinching...", isPinching);
    if (isPinching) {
      isPinching = false; // Reset pinching state
      console.log("Pinch ended");
    }
    console.log("touchend Exiting ...");
  });
};

/**
 * Add DocumentViewer event listeners.
 */
function addDocumentViewerEventListeners() {
  const { documentViewer, annotationManager } = _instance.Core;
  documentViewer.addEventListener("documentLoaded", () => {
    console.log("Document is loaded.");
    let _exhibitStamp = null;
    let _hasExhibitStamp = false;

    // If the PDF has been published already, the annotations are already merged.
    // if (_pdfStatus === "published") return;
    console.log("Loading annotations into PDF...");
    _instance.UI.hotkeys.off();
    listAnnotations(_pdfId).then(async (resp) => {
      // Add annotations to Exhibit and redraw
      _exhibitAnnotations = resp;
      if (_exhibitAnnotations != null) {
        const annotations = await annotationManager.importAnnotations(
          _exhibitAnnotations.xfdf
        );
        annotations.forEach((annotation) => {
          if (isAnnotationExhibitStamp(annotation)) {
            updateStampSelection(true);
          }

          if (annotation.Subject === "MyExhibitStamp") {
            _exhibitStamp = annotation;
            _hasExhibitStamp = true;

            // When loading a document that has an Exhibit stamp, we need to get its number and update
            // the global var `_exhibitNumber` to get the right number when the doc is published
            _exhibitNumber = annotation
              ?.getGroupedChildren()[0]
              ?.getCustomData("stampExhibitNumber");

            updateStampSelection(_hasExhibitStamp);
          }
          // if (annotation.Subject === "Stamp") {
          //   _exhibitStampNumber = annotation;
          // }
        });
      }
    });

    console.log("Updating exhibit settings into PDF...", _exhibitSettings);
    setExhibitSettings(_exhibitSettings);
  });
}

/**
 * Callback for scroll page event used to send a websocket event to synch scrolling the event participants pages
 */
let isScrolling, topPosition;
function pageScrollCallback() {
  // Set top position
  const scrollView = getScrollViewElement();
  topPosition = scrollView.scrollTop;

  // Clear our timeout throughout the scroll
  window.clearTimeout(isScrolling);

  // Set a timeout to run after scrolling ends, 60 is screen refresh rate
  isScrolling = setTimeout(function () {
    if (hasAnnotationPermissions()) {
      emitPageScrolling(_eventId, _username, topPosition);
    }
  }, 60);
}

/**
 * Add event listeners for emitting SocketIO messages based on
 * DocumentViewer events, such as pageNumberUpdated, rotationUpdated, etc.
 */
function addSocketEventListeners() {
  console.log("Adding socket event listeners...");
  // Event listener to catch the page scroll position
  const { documentViewer } = _instance.Core;
  const scrollView = getScrollViewElement();
  scrollView?.addEventListener("scroll", pageScrollCallback);

  //Event listner to catch pageNumber update
  documentViewer.addEventListener("pageNumberUpdated", (pageNumber) => {
    // onPageUpdated(pageNumber);
    if (hasAnnotationPermissions()) {
      emitPageNumberUpdated(_eventId, _username, pageNumber);
    }
  });

  documentViewer.addEventListener("rotationUpdated", (rotation, pageIndex) => {
    if (hasAnnotationPermissions()) {
      emitRotationUpdated(_eventId, _username, rotation);
    }
  });

  documentViewer.addEventListener("zoomUpdated", (zoom) => {
    if (hasAnnotationPermissions()) {
      emitZoomUpdated(_eventId, _username, zoom);
    }
  });

  documentViewer.addEventListener("displayModeUpdated", () => {
    const displayMode = documentViewer
      .getDisplayModeManager()
      .getDisplayMode().mode;

    if (hasAnnotationPermissions()) {
      emitDisplayModeUpdated(_eventId, _username, displayMode);
    }
  });
}

/**
 * Remove DocumentViewer event listeners.
 */
function removeSocketEventListeners() {
  const { documentViewer } = _instance.Core;
  const scrollView = getScrollViewElement();

  documentViewer.removeEventListener("pageNumberUpdated");
  documentViewer.removeEventListener("rotationUpdated");
  documentViewer.removeEventListener("zoomUpdated");
  documentViewer.removeEventListener("displayModeUpdated");
  scrollView.removeEventListener("scroll", pageScrollCallback);
}

/**
 * Keep only non exhibit rubber stamps if the document has already one.
 * @param {boolean} hasExhibitStamp
 */
function updateStampSelection(hasExhibitStamp) {
  console.trace(
    "useWebViewer::addAnnotationManagerEventListeners:updateStampSelection hasExhibitStamp ",
    hasExhibitStamp
  );
  const { documentViewer } = _instance.Core;
  const tool = documentViewer.getTool("AnnotationCreateRubberStamp");
  if (!hasExhibitStamp) {
    console.trace(
      "useWebViewer::addAnnotationManagerEventListeners:updateStampSelection Before setting tool"
    );
    tool.setStandardStamps([
      "/images/stamp_attorneyOnly.svg",
      "/images/stamp_confidential.svg",
      "/images/stamp_highlyConfidential.svg",
      "/images/stamp_exhibit.svg",
      "/images/stamp_defendant.svg",
      "/images/stamp_plaintiff.svg"
    ]);
  } else {
    tool.setStandardStamps([
      "/images/stamp_attorneyOnly.svg",
      "/images/stamp_confidential.svg",
      "/images/stamp_highlyConfidential.svg"
    ]);
  }
}

/**
 * Add AnnotationManager Event Listeners.
 */
function addAnnotationManagerEventListeners() {
  const { annotationManager } = _instance.Core;
  const authorId = _username;

  annotationManager.addEventListener(
    "annotationChanged",
    async (annotations, type, info) => {
      console.log("useWebViewer::changed", _exhibitNumber);
      if (info.imported) return;

      // If the user is not an annotator, then do not allow annotations to be emitted.
      if (!isAnnotator()) return;

      const xfdf = await annotationManager.exportAnnotations({
        links: false,
        widgets: false
      });

      annotations.forEach((annotation) => {
        const parentAuthorId = null;
        if (type === "add") {
          if (isAnnotationExhibitStamp(annotation) && !_exhibitNumber) {
            updateStampSelection(true);
            console.trace(
              "useWebViewer::addAnnotationManagerEventListeners _existingExhibitNumber ",
              _existingExhibitNumber
            );
            if (_existingExhibitNumber) {
              _exhibitNumber = _existingExhibitNumber;
              _isExhibitsNumbersAutoIncremented = false;
              addExhibitStampTextField(annotation);
            }

            // get the next exhibit number.
            else {
              getNextExhibitNumber(_eventId).then((nextResp) => {
                _exhibitNumber = nextResp.data.nextExhibitNumber;
                console.trace(
                  "useWebViewer::addAnnotationManagerEventListeners:inside getNextExhibitNumber _exhibitNumber",
                  _exhibitNumber
                );
                _isExhibitsNumbersAutoIncremented =
                  nextResp.data.isExhibitsNumbersAutoIncremented;
                addExhibitStampTextField(annotation);
              });
            }
          }

          emitAnnotationCreated(_eventId, annotation.Id, {
            authorId,
            parentAuthorId,
            xfdf
          });
        } else if (type === "modify") {
          // Emit SocketIO message
          emitAnnotationUpdated(_eventId, annotation.Id, {
            authorId,
            parentAuthorId,
            xfdf
          });
        } else if (type === "delete") {
          if (isAnnotationExhibitStamp(annotation)) {
            updateStampSelection(false);
            _exhibitNumber = "";
          }

          // Emit SocketIO message
          emitAnnotationDeleted(_eventId, annotation.Id, {
            authorId,
            parentAuthorId,
            xfdf
          });
        }

        // Save annotations to server
        // console.log("XXX saving annotation...", annotation.Id, xfdf);
        if (isAnnotationExhibitStamp(annotation) && !_exhibitNumber) return;
        saveAnnotation(_pdfId, annotation.Id, xfdf);
      });
    }
  );
}

/**
 * Event handler for display mode updated.
 *
 * @param {*} data
 */
const onDisplayModeUpdated = async (data) => {
  console.log("Handling onDisplayModeUpdated...", data);
  _instance.setLayoutMode(data.layoutMode);
};

/**
 * Event handler for when an exhibit is published.
 *
 * @param {*} data
 */
const onExhibitPublish = async (data) => {
  if (!isPresenter()) {
    if (data.state === "in-progress") {
      _handleParticipantPublishState((prevV) => ({
        ...prevV,
        isPublishing: true,
        state: "in-progress"
      }));
    } else if (data.state === "complete") {
      _handleParticipantPublishState((prevV) => ({
        ...prevV,
        isPublishing: false,
        state: "complete"
      }));
    }
  }
};

/**
 * Get scrollView element.
 *
 * @returns
 */
const getScrollViewElement = () => {
  const { documentViewer } = _instance.Core;
  const scrollView = documentViewer.getScrollViewElement();
  return scrollView;
};

/**
 * Event handler for page updated.
 *
 * @param {*} data
 */
const onPageUpdated = async (data) => {
  const { documentViewer } = _instance.Core;
  documentViewer.setCurrentPage(data.pageNumber, true);
};

/**
 * Event handler for zoom updated.
 *
 * @param {*} data
 */
const onZoomUpdated = async (data) => {
  const { documentViewer } = _instance.Core;
  documentViewer.zoomTo(data.zoom);
};

/**
 * Event handler for rotation updated.
 *
 * @param {*} data
 */
const onRotationUpdated = async (data) => {
  const { documentViewer } = _instance.Core;
  // console.log("Handling onRotationUpdated...", data);
  documentViewer.setRotation(data.rotation);
};

/**
 * Event handler for page scrolling.
 *
 * @param {*} data
 */
const onPageScrolling = async (data) => {
  // const { documentViewer } = _instance.Core;
  // documentViewer.setCurrentPage(data.pageNumber, true)

  const scrollView = getScrollViewElement();
  scrollView.scroll({
    left: null, // Can set to null since we only care about vertical scrolling.
    top: data.position,
    behavior: "smooth"
  });
};

/**
 * Event handler for annotation created.
 *
 * @param {*} data
 */
const onAnnotationCreated = async (data) => {
  // Import the annotation based on xfdf command
  const { annotationManager } = _instance.Core;
  const annotations = await annotationManager.importAnnotations(data.xfdf);
  if (annotations) {
    annotations.authorId = data.authorId;
    annotationManager.redrawAnnotation(annotations);
  }
};

/**
 * Event handler for annotation updated.
 *
 * @param {*} data
 */
const onAnnotationUpdated = async (data) => {
  // Import the annotation based on xfdf command
  const { annotationManager } = _instance.Core;
  const annotations = await annotationManager.importAnnotations(data.xfdf);
  if (annotations) {
    annotations.authorId = data.authorId;
    annotationManager.redrawAnnotation(annotations);
  }
};

/**
 * Event handler for annotation deleted.
 *
 * @param {*} annotationId
 */
const onAnnotationDeleted = async (annotationId) => {
  const { annotationManager } = _instance.Core;
  const deletedAnnotation = annotationManager.getAnnotationById(annotationId);
  annotationManager.deleteAnnotation(deletedAnnotation, { force: true });
};

/**
 * Event handler for annotation changed.
 *
 * @param {*} data
 */
const onAnnotatorChanged = async (data) => {
  console.log("Handling socket event for 'annotatorChanged'...", data);
  _annotator = data.annotator;
  updateEditorHeaderAndToolbars(isPresenter(), isAnnotator());
  _onAnnotatorRightsChanged(data.annotator);
};

/**
 * Load the Exhibit from HusebyConnect
 *
 * @param {*} fileId
 */
const loadExhibit = async (fileId, isEditorMode) => {
  // Register the PDF from the specified fileId.
  console.log("Registering PDF...");
  const _pdf = await importFile(_fileId);
  _pdfId = _pdf.pdfId;
  _pdfStatus = _pdf.status;
  console.log("PDF registered!", _pdfId, _pdfStatus);

  console.log("Loading Exhibit into WebViewer....");
  const file = await viewFile(fileId, isEditorMode);
  await _instance.UI.loadDocument(file.s3Url);
};

/**
 * Load Exhibit from blob.
 *
 * @param {*} documentBlob
 */
const loadExhibitFromBlob = (documentBlob) => {
  console.log("Loading Exhibit from blob into WebViewer....");

  _instance.UI.loadDocument(documentBlob, {
    filename: "exhibit.pdf"
  });
};

/**
 * Check if user has annotation permissions.
 *
 * @returns
 */
const hasAnnotationPermissions = () => {
  return isAnnotator();
};

/**
 * Check if the user is a presenter.
 *
 * @returns
 */
const isPresenter = () => {
  return _presenter?.username === _username;
};

/**
 * Check if the user is an annotator.
 *
 * @returns
 */
const isAnnotator = () => {
  return _annotator?.username === _username;
};

/**
 * Check if the annotation is an exhibit stamp.
 *
 * @param {*} annotation
 * @returns
 */
const isAnnotationExhibitStamp = (annotation) => {
  // Need to check Toolname also when deleeting an Exhibit stamp
  // To readd removed stamps from the dropdown if deletting an exhibt stamp from the Doc
  // and that correspond to annotation.ToolName === "AnnotationCreateStamp"
  if (
    annotation.ToolName === "AnnotationCreateRubberStamp" ||
    annotation.ToolName === "AnnotationCreateStamp"
  ) {
    // Breaking change in the PDFJS express versions.
    // v8.4.0 has a bug for rubber stamps.
    // v8.1.1 : annotation.Ei && annotation.Ei["trn-original-url"]
    // v8.2.0 : annotation.zi && annotation.zi["trn-original-url"]
    // v8.4.0 : annotation.Pi && annotation.Pi["trn-original-url"]
    const annotationType = annotation.zi && annotation.zi["trn-original-url"];

    // Check if this is an exhibit stamp.
    return isExhibitStamp(annotationType);
  }

  return false;
};

/**
 * Check if the annotation is an exhibit stamp.
 *
 * @param {string} annotationType - the stamp image path
 * @returns {boolean}
 */
const isExhibitStamp = (annotationType) => {
  return (
    !isNil(annotationType) &&
    [
      "/images/stamp_exhibit.svg",
      "/images/stamp_defendant.svg",
      "/images/stamp_plaintiff.svg"
    ].includes(annotationType)
  );
};

/**
 * Add StampAnnotation to Exhibit Stamp.   The Exhibit Stamp is composed of two
 * components: (a) Rubber Stamp and (b) custom StampAnnotation.
 *
 * @param {*} annotation
 */
const addExhibitStampTextField = async (annotation) => {
  // const { annotationManager, Annotations } = _instance.Core;
  _exhibitStampAnnotation = annotation;

  // Display prompt to enter exhibitNumber.
  if (!_isExhibitsNumbersAutoIncremented) await _onExhibitNumberClick();
  else onExhibitNumberUpdated(_exhibitNumber);
};

/**
 * Remove Exhibit Stamp if no number is entered.  case where the dialog is cancelled.
 *
 * @param {*} annotation
 */
export const onCancelExhibitNumber = () => {
  const { annotationManager } = _instance.Core;
  annotationManager.deleteAnnotation(_exhibitStampAnnotation);
  _exhibitStampAnnotation = null;
};

/**
 * This is where the custom exhibit stamp is generated that combines a stamp and a
 * text field.
 *
 * @param {*} exhibitNumber
 */
export const onExhibitNumberUpdated = (exhibitNumber) => {
  const { annotationManager, documentViewer } = _instance.Core;

  const pageNumber = _exhibitStampAnnotation.PageNumber;
  //  PageRotation: 0 (0 degress), 1 (90 degrees), 2 (180 degrees), 3 (270 degrees)
  const pageRotation = documentViewer.getRotation(pageNumber);

  _exhibitStampAnnotation.NoRotate = true;
  _exhibitStampAnnotation.NoResize = true;
  _exhibitStampAnnotation.Subject = "MyExhibitStamp";

  // create a canvas in memory to draw your text to
  const canvas = document.createElement("canvas");
  canvas.width = 104;
  canvas.height = 52;
  const ctx = canvas.getContext("2d");

  // Get reference to exhibit stamp image.
  let img = new Image();
  img.src =
    _exhibitStampAnnotation.zi &&
    _exhibitStampAnnotation.zi["trn-original-url"];

  img.onload = function () {
    ctx.drawImage(img, 0, 0, 104, 52);
    ctx.fillText(exhibitNumber, 50 - exhibitNumber.length * 3.33, 40);

    const dataURL = canvas.toDataURL();
    _exhibitStampAnnotation.setImageData(dataURL);

    // We store the `ExhibitNumber` in a custom field linked to the stamp in order to get it back
    // when the document is loaded, and that will be used when publishing the document
    _exhibitStampAnnotation.setCustomData("stampExhibitNumber", exhibitNumber);
    annotationManager.redrawAnnotation(_exhibitStampAnnotation);
    annotationManager.selectAnnotations([_exhibitStampAnnotation]);

    // We need to manually emit a socket message since just adding a text field and updating the
    // stamps' image data doesn't dispatch a "changed" event.
    annotationManager
      .exportAnnotations({
        links: false,
        widgets: false
      })
      .then((xfdf) => {
        const authorId = _username;
        const parentAuthorId = null;

        emitAnnotationUpdated(_eventId, _exhibitStampAnnotation.Id, {
          authorId,
          parentAuthorId,
          xfdf
        });
      });

    // Set exhibitNumber
    _exhibitNumber = exhibitNumber;
  };
};

/**
 * Check if the exhibit has exhibit stamps.
 *
 * @param {*} onPublishExhibitConfirmClick
 */
const publishExhibitConfirm = (onPublishExhibitConfirmClick) => {
  console.log("Checking the Exhibit has stamps...");
  const { annotationManager } = _instance.Core;

  let hasStamp = false;
  // let annotationType = null;
  const annotations = annotationManager.getAnnotationsList();
  annotations.forEach((annotation) => {
    // console.log("XXXXXX annotation", annotation);
    // annotationType = annotation.Pi && annotation.Pi["trn-original-url"];
    // if (isExhibitStamp(annotationType)) {
    //   hasStamp = true;
    // }
    if (isAnnotationExhibitStamp(annotation)) hasStamp = true;
  });

  onPublishExhibitConfirmClick(hasStamp);
};

/**
 * Merge Exhibit with annotations.   This sends the PDF and annotations over to PDFJS and merges into a single PDF.
 */
const mergeExhibit = async () => {
  const { annotationManager } = _instance.Core;

  // Flatten the PDF and make it read-only.  There are certain viewers not honoring the read-only flag:
  // https://pdfjs.community/t/merge-rest-api-how-to-flatten-pdf/328/8
  const list = annotationManager.getAnnotationsList();
  list.forEach((item) => {
    item.ReadOnly = true;
  });
  console.log("merging Exhibit with annotation....");
  const file = await viewFile(_fileId, true);
  const xfdf = await annotationManager.exportAnnotations({
    links: false,
    widgets: false
  });

  utils.setFile(file.s3Url).setXFDF(xfdf);

  const response = await utils.merge(); // merge XFDF

  const mergedFile = await response.getBlob();

  return mergedFile;
};

/**
 * Publish the exhibit.  This consists of merging the PDF and annotations into a
 * single blob, marking the exhibit as published and updating the exhibitNumber.
 * The Exhibit Editor is then reloaded with the finalized exhibit.
 */
export const publishExhibit = async () => {
  console.log("Preparing exhibit for publish...");

  console.log("Merging the Exhibit...");
  const exhibitBlob = await mergeExhibit();
  console.log("   merged exhibit from documentBlob", exhibitBlob);

  console.log("Mark the Exhibit as published and update exhibitNumber");
  _pdfStatus = "published"; // Set the current state to published, so when document is reloaded, the annots will be locked.
  await publishExhibitAndUpdateExhibitNumber(
    _pdfId,
    _exhibitNumber,
    exhibitBlob
  );
  _exhibitNumber = "";

  // Display the exhibit
  console.log("Loading exhibit from merged documentedBlob...");
  loadExhibitFromBlob(exhibitBlob);

  // Lock the exhibit after publishing
  lockExhibit();

  // Return exhibit
  return exhibitBlob;
};

/**
 * Lock the Exhibit.
 */
export const lockExhibit = () => {
  console.log("Locking Exhibit...");
  _instance.UI.disableElements(["toolsHeader"]);
  _instance.UI.disableElements(["publishButton"]);
};

/**
 * Set the Exhibit Settings and refresh the documentViewer to
 * display the pageNumber, rotation, zoom and layoutMode set in
 * the exhibitSettings.
 *
 * @param {*} exhibitSettings
 */
export const setExhibitSettings = (exhibitSettings) => {
  if (isNil(exhibitSettings) || isNil(_instance) || isNil(_instance.Core))
    return;

  _exhibitSettings = exhibitSettings;

  console.log(
    "Refreshing the documentViewer to display pageNumber, rotation, zoom, layout."
  );
  const { documentViewer } = _instance.Core;
  documentViewer.setCurrentPage(_exhibitSettings.pageNumber);
  documentViewer.setRotation(_exhibitSettings.rotation);
  documentViewer.zoomTo(_exhibitSettings.zoom);
  _instance.setLayoutMode(_exhibitSettings.layoutMode);
};

/**
 * Update annotator.
 *
 * @param {*} annotator
 */
export const updateAnnotator = (annotator) => {
  _annotator = annotator;
};

/**
 * Update Exhibit Previewer header and toolbars.
 */
const updatePreviewHeaderAndToolbars = () => {
  _instance.UI.disableElements(["toolsHeader"]);
  // disableElements(["leftPanelButton"]);
  _instance.UI.disableElements(["panToolButton"]);
  _instance.UI.disableElements(["selectToolButton"]);
  _instance.UI.disableElements(["ribbons"]);
  // disableElements(["searchButton"]);
  _instance.UI.disableElements(["toggleNotesButton"]);
  _instance.UI.disableElements(["menuButton"]);

  _instance.UI.disableFeatures([_instance.UI.Feature.TextSelection]);
  _instance.UI.disableFeatures([_instance.UI.Feature.Annotations]);

  // Hide dividers
  _instance.UI.setHeaderItems((header) => {
    const items = header.getItems().filter((item) => item.type !== "divider");
    header.update(items);
  });
};

/**
 * Update Header and Toolbars
 *
 * @param {*} isPreview
 */
const updateHeaderAndToolbars = (isPreview) => {
  _instance.UI.enableElements(["header"]);
  // Disable ungrouping exhibit rubber stamp
  _instance.UI.disableElements(["annotationUngroupButton"]);

  // Update the header and toolbars for isPreview.
  if (isPreview) {
    updatePreviewHeaderAndToolbars();
  } else {
    updateEditorHeaderAndToolbars();
  }

  if (_filePermissions?.grantDownload) {
    _instance.UI.setHeaderItems(addDownloadExhibitButton);
  }

  _instance.UI.setHeaderItems(addRefreshExhibitButton);

  // Add the launch exhibit button if the user is
  // a host and they are in preview mode and the exhibitMode is ADVANCED_MODE.
  // This happens if the user is in a meeting.
  if (
    isPresenter() &&
    _isPreviewMode &&
    _isMeetingRoom &&
    _exhibitMode === ADVANCED_MODE
  ) {
    _instance.UI.setHeaderItems(addLaunchExhibitEditorButton);
  }

  // Add the close button last.
  if (_useDialog && (isPresenter() || isPreview)) {
    _instance.UI.setHeaderItems(addCloseButton);
  }
};

/**
 * Update Exhibit Editor header and toolbars.
 *
 * @param {*} annotator
 */
export const updateEditorHeaderAndToolbars = () => {
  // Disable headers and toolbars that aren't used by the Editor.
  _instance.UI.disableElements([
    "toolsHeader",
    "ribbonsDropdown",
    "ribbons",
    "menuButton",
    "freeTextToolButton",
    "stickyToolButton",
    "underlineToolGroupButton",
    "strikeoutToolGroupButton",
    "squigglyToolGroupButton",
    "freeTextToolGroupButton",
    "stickyToolGroupButton",
    "ellipseToolGroupButton",
    "crossStampToolButton",
    "checkStampToolButton"
  ]);

  if (!isPresenter() && !isAnnotator()) {
    // Disable Annotation and Shapes features.
    _instance.UI.disableTools();
    _instance.UI.disableFeatures([_instance.UI.Feature.Annotations]);
    _instance.UI.disableFeatures([_instance.UI.Feature.Shapes]);
    // disableFeatures([Feature.TextSelection]);

    // Hide the popups
    _instance.UI.annotationPopup.update([]);
    _instance.UI.contextMenuPopup.update([]);
    _instance.UI.textPopup.update([]);
    _instance.UI.setAnnotationContentOverlayHandler(() => {
      return false;
    });

    // Disable header buttons
    _instance.UI.disableElements([
      "selectToolButton",
      "toggleNotesButton",
      "publishButton",
      "pageNavOverlay"
    ]);

    // Hide dividers
    _instance.UI.setHeaderItems((header) => {
      const items = header.getItems().filter((item) => item.type !== "divider");
      header.update(items);
    });

    console.log(
      "useWebViewer::updateEditorHeaderAndToolbars Loading annotations into PDF..."
    );
    const { annotationManager } = _instance.Core;
    _instance.UI.hotkeys.off();
    listAnnotations(_pdfId).then(async (resp) => {
      // Add annotations to Exhibit and redraw
      _exhibitAnnotations = resp;

      if (_exhibitAnnotations != null) {
        const annotations = await annotationManager.importAnnotations(
          _exhibitAnnotations.xfdf
        );
      }
    });
    return;
  }

  // Enable 'Annotation' toolbars
  if (isAnnotator()) {
    _instance.UI.enableTools();
    _instance.UI.enableFeatures([_instance.UI.Feature.Annotations]);
    _instance.UI.enableFeatures([_instance.UI.Feature.Shapes]);

    // Enable tools header.
    _instance.UI.enableElements(["toolsHeader"]);

    // Default to the Annotations toolbar group.
    _instance.UI.setToolbarGroup("toolbarGroup-Annotate");

    // Enable line and arrow
    _instance.UI.setHeaderItems(setExhibitEditorHeaderItems);
  }

  // Enable 'Publish Exhibits' and 'Annotation Rights' button
  if (isPresenter()) {
    _instance.UI.setHeaderItems(addAnnotationRightsButton);
    _instance.UI.setHeaderItems(addPublishExhibitButton);
  }
};

/**
 * Set Exhibit Editor headers
 *
 * @param {*} header
 */
function setExhibitEditorHeaderItems(header) {
  const annotateHeader = header.getHeader("toolbarGroup-Annotate").getItems();
  if (!hasDataElement(annotateHeader, "lineToolGroupButton")) {
    header.getHeader("toolbarGroup-Annotate").push({
      type: "toolGroupButton",
      toolGroup: "lineTools",
      dataElement: "lineToolGroupButton",
      title: "annotation.line"
    });
  }

  if (!hasDataElement(annotateHeader, "arrowToolGroupButton")) {
    header.getHeader("toolbarGroup-Annotate").push({
      type: "toolGroupButton",
      toolGroup: "arrowTools",
      dataElement: "arrowToolGroupButton",
      title: "annotation.arrow"
    });
  }

  if (isPresenter()) {
    if (!hasDataElement(annotateHeader, "rubberStampToolGroupButton")) {
      header.getHeader("toolbarGroup-Annotate").push({
        type: "toolGroupButton",
        toolGroup: "rubberStampTools",
        dataElement: "rubberStampToolGroupButton",
        title: "annotation.rubberStamp"
      });
    }

    // header.getHeader("toolbarGroup-Shapes").delete(6);
    const { documentViewer } = _instance.Core;
    const tool = documentViewer.getTool("AnnotationCreateRubberStamp");
    tool.setStandardStamps([
      "/images/stamp_attorneyOnly.svg",
      "/images/stamp_confidential.svg",
      "/images/stamp_highlyConfidential.svg",
      "/images/stamp_exhibit.svg",
      "/images/stamp_defendant.svg",
      "/images/stamp_plaintiff.svg"
    ]);
  }

  header.getHeader("toolbarGroup-Shapes").delete(6);
}

/**
 * Add Annotation Rights button.
 *
 * @param {*} header
 */
function addAnnotationRightsButton(header) {
  if (hasDataElement(header, "annotationRightsButton")) return;

  header.push({
    type: "actionButton",
    img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:#abb0c4;}</style></defs><title>icon - header - download</title><path d="M11 14H9c0-4.97 4.03-9 9-9v2c-3.87 0-7 3.13-7 7m7-3V9c-2.76 0-5 2.24-5 5h2c0-1.66 1.34-3 3-3M7 4c0-1.11-.89-2-2-2s-2 .89-2 2 .89 2 2 2 2-.89 2-2m4.45.5h-2C9.21 5.92 7.99 7 6.5 7h-3C2.67 7 2 7.67 2 8.5V11h6V8.74c1.86-.59 3.25-2.23 3.45-4.24M19 17c1.11 0 2-.89 2-2s-.89-2-2-2-2 .89-2 2 .89 2 2 2m1.5 1h-3c-1.49 0-2.71-1.08-2.95-2.5h-2c.2 2.01 1.59 3.65 3.45 4.24V22h6v-2.5c0-.83-.67-1.5-1.5-1.5"></path></svg>',
    onClick: () => {
      _onAnnotationRightsClick();
    },
    title: "Annotation Rights", // QW-TODO: Update the title by looking up the header item using javascript.
    dataElement: "annotationRightsButton"
  });
}

/**
 * Add Publish Exhibit button.
 *
 * @param {*} header
 */
function addPublishExhibitButton(header) {
  if (hasDataElement(header, "publishButton")) return;

  header.push({
    type: "actionButton",
    img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
    onClick: () => publishExhibitConfirm(_onPublishExhibitConfirmClick),
    title: "Publish Exhibit",
    dataElement: "publishButton"
  });
}

/**
 * Add Launch Exhibit button.
 *
 * @param {*} header
 */
function addLaunchExhibitEditorButton(header) {
  header.push({
    type: "actionButton",
    img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:#abb0c4;}</style></defs><title>icon - header - download</title><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></svg>',
    onClick: () => _onLaunchExhibitEditor(_fileId),
    title: "Launch Exhibit"
    // dataElement: "downloadButton"
  });
}

/**
 * Add Download Exhibit button.
 *
 * @param {*} header
 */
function addDownloadExhibitButton(header) {
  header.push({
    type: "actionButton",
    img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:#abb0c4;}</style></defs><title>icon - header - download</title><path class="cls-1" d="M11.55,17,5.09,9.66a.6.6,0,0,1,.45-1H8.67V2.6a.6.6,0,0,1,.6-.6h5.46a.6.6,0,0,1,.6.6V8.67h3.13a.6.6,0,0,1,.45,1L12.45,17A.6.6,0,0,1,11.55,17ZM3.11,20.18V21.6a.4.4,0,0,0,.4.4h17a.4.4,0,0,0,.4-.4V20.18a.4.4,0,0,0-.4-.4h-17A.4.4,0,0,0,3.11,20.18Z"/></svg>',
    onClick: () => _onDownloadExhibitClick(_fileId),
    title: "Download Exhibit",
    dataElement: "downloadFileButton"
  });
}

/**
 * Add Refresh Exhibit button.
 *
 * @param {*} header
 */
function addRefreshExhibitButton(header) {
  header.push({
    type: "actionButton",
    img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.65 6.35A7.958 7.958 0 0 0 12 4a8 8 0 0 0-8 8a8 8 0 0 0 8 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0 1 12 18a6 6 0 0 1-6-6a6 6 0 0 1 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" fill="currentColor"/></svg>',
    onClick: () => loadExhibit(_fileId, false),
    title: "Refresh Exhibit",
    dataElement: "refreshButton"
  });
}

/**
 * Add Close button.
 *
 * @param {*} header
 */
function addCloseButton(header) {
  const _closeButton = header
    .getItems()
    .find((item) => item.dataElement === "closeButton");
  if (!isNil(_closeButton)) return;

  header.push({
    id: "close",
    type: "actionButton",
    img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:#abb0c4;}</style></defs><title>icon - header - close</title><path class="cls-1" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>',
    onClick: () => handleCloseClick(),
    title: "Close exhibit",
    dataElement: "closeButton"
  });
}

function handleCloseClick() {
  _exhibitNumber = "";
  _instance.UI.closeDocument();
  _onCloseClick();
}

const hasDataElement = (header, dataElement) => {
  let exists = false;
  if (Array.isArray(header)) {
    exists = header.findIndex((ele) => ele.dataElement === dataElement) !== -1;
  } else {
    exists =
      header.getItems().findIndex((ele) => ele.dataElement === dataElement) !==
      -1;
  }
  return exists;
};
